
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { settingsRules, modifyRules } from '../../rules/appbar-rules'
import { defineComponent, computed, ref, Ref, reactive, getCurrentInstance, ComputedRef, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { useRouter } from 'vue-router'

interface ISettingForms {
  nickname: string;
  // avatar: string;
  bio: string;
}

interface IModifyForms {
  mobile: string;
  captcha: string;
  newpassword: string;
}

declare let globalConfig:any

export default defineComponent({
  name: 'DashboardAppBar',
  data () {
    return {}
  },
  setup () {
    const store = useStore()
    // const router = useRouter()
    const { proxy } :any = getCurrentInstance()
    const config = globalConfig
    const router = useRouter()
    const userInfo = computed(() => store.getters.GET_USER_INFO)
    const responsively: ComputedRef<boolean> = computed(() => store.getters.GET_RESPONSIVELY_MODE)
    const settingDialog: Ref<boolean> = ref(false)
    const modifyDialog: Ref<boolean> = ref(false)
    const sendSms: Ref<boolean> = ref(false)

    const settingForms = reactive<ISettingForms>({
      nickname: '',
      bio: ''
    })

    const modifyForms = reactive<IModifyForms>({
      mobile: '',
      captcha: '',
      newpassword: ''
    })

    const cutover = ():void => {
      store.commit('SET_MENU_BAR', !store.getters.GET_MENU_BAR)
    }

    const switchScreen = ():void => {
      store.commit('SET_WIDE_SCREEN', !store.getters.GET_WIDE_SCREEN)
    }

    const wideScreen = computed<boolean>(() => {
      return store.getters.GET_WIDE_SCREEN
    })

    const exit = (): void => {
      proxy.$nextTick(() => {
        const routerUrl = router.resolve({ path: '/' })
        location.href = routerUrl.href
        store.commit('Logout')
      })
    }

    const editProfile = (): void => {
      proxy.$refs.settingForm.validate((valid: boolean) => {
        if (valid) {
          proxy.$axios.post('/user/profile', {
            nickname: settingForms.nickname,
            bio: settingForms.bio
          }).then((res: IResponse) => {
            if (res.code === 1) {
              ElMessage.success({
                message: res.msg,
                type: 'success'
              })
              store.commit('SET_USER_INFO', res.data)
            } else {
              ElMessage.warning({
                message: res.msg,
                type: 'warning'
              })
            }
            settingDialog.value = false
          })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 设置密码操作
    const modifyPassword = (): void => {
      proxy.$refs.modifyForm.validate((valid: boolean) => {
        if (valid) {
          proxy.$axios.post('/user/resetpwd', modifyForms)
            .then((res: IResponse) => {
              if (res.code === 200) {
                ElMessage.success({
                  message: res.msg,
                  type: 'success'
                })
              } else {
                ElMessage.warning({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = modifyForms.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'resetpwd'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    onMounted(() => {
      settingForms.nickname = userInfo.value && userInfo.value.nickname
      modifyForms.mobile = userInfo.value && userInfo.value.mobile
    })

    return {
      settingDialog,
      modifyDialog,
      editProfile,
      userInfo,
      exit,
      settingForms,
      modifyForms,
      responsively,
      settingsRules,
      modifyRules,
      modifyPassword,
      handleSms,
      sendSms,
      cutover,
      switchScreen,
      wideScreen,
      config
    }
  }
})
