<template>
  <div class="appbar">
    <el-row class="pc" v-if="!responsively">
      <el-col class="left" :span="12">
        <router-link class="logo" to="/">
          <el-image :src="config.logo"></el-image>
        </router-link>
      </el-col>
      <el-col class="right" :span="12">
        <el-switch
          v-model="wideScreen"
          @click="switchScreen"
          inactive-text="宽屏模式"
        >
        </el-switch>
        <div class="dropdown">
          <el-dropdown>
            <span class="avatar-dropdown el-dropdown-link">
              <el-avatar :src="userInfo.avatar"></el-avatar>
              <div class="user-name">
                {{ userInfo.nickname }}
              </div>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="settingDialog = true">个人设置</el-dropdown-item>
                <el-dropdown-item @click="modifyDialog = true">修改密码</el-dropdown-item>
                <el-dropdown-item @click="exit" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <el-row class="mobile" v-else>
      <el-col :span="6">
        <em class="el-icon-s-unfold" @click="cutover"></em>
      </el-col>
      <!-- <el-col class="logo" :span="12">
        <el-image :src="config.logo"></el-image>
      </el-col> -->
      <el-col :span="6" :offset="12">
        <div class="dropdown">
          <el-dropdown trigger="click">
            <span class="avatar-dropdown el-dropdown-link">
              <el-avatar :src="userInfo.avatar"></el-avatar>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="settingDialog = true">个人设置</el-dropdown-item>
                <el-dropdown-item @click="modifyDialog = true">修改密码</el-dropdown-item>
                <el-dropdown-item @click="exit" divided>退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <teleport to="body">
      <el-dialog title="个人设置" :fullscreen="responsively" v-model="settingDialog">
        <el-form ref="settingForm" :model="settingForms" status-icon :rules="settingRules">
          <el-form-item label="用户昵称">
            <el-input v-model="settingForms.nickname" placeholder="请输入用户昵称" autocomplete="off"></el-input>
          </el-form-item>
          <!-- <el-form-item label="用户头像" :label-width="formLabelWidth"> -->
            <!-- <el-upload
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload> -->
            <!-- <el-input placeholder="暂只支持网络地址" v-model="settingForms.avatar" autocomplete="off"></el-input>
          </el-form-item> -->
          <el-form-item label="个人简介">
            <el-input placeholder="请输入个人简介" type="textarea" :autosize="{ minRows: 2 }" :maxlength="200" show-word-limit v-model="settingForms.bio" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="settingDialog = false">取 消</el-button>
            <el-button type="primary" @click="editProfile">确 定</el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog title="修改密码" :fullscreen="responsively" v-model="modifyDialog">
        <el-form ref="modifyForm" status-icon :model="modifyForms" :rules="modifyRules">
          <el-form-item label="手机号" prop="mobile">
            <el-input disabled placeholder="请输入手机号" clearable v-model="modifyForms.mobile" auto-complete="off"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="captcha">
            <el-input placeholder="请输入验证码" v-model="modifyForms.captcha" auto-complete="off">
              <template #append>
                <el-button @click="handleSms" :loading="sendSms">获取验证码</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="newpassword">
            <el-input placeholder="请输入新的登录密码" show-password v-model="modifyForms.newpassword" auto-complete="off"></el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="modifyDialog = false">取 消</el-button>
            <el-button type="primary" @click="modifyPassword">确 定</el-button>
          </span>
        </template>
      </el-dialog>
    </teleport>
  </div>
</template>

<script lang="ts">
import { IResponse } from '@/common/http'
import { ElMessage } from 'element-plus'
import { settingsRules, modifyRules } from '../../rules/appbar-rules'
import { defineComponent, computed, ref, Ref, reactive, getCurrentInstance, ComputedRef, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { useRouter } from 'vue-router'

interface ISettingForms {
  nickname: string;
  // avatar: string;
  bio: string;
}

interface IModifyForms {
  mobile: string;
  captcha: string;
  newpassword: string;
}

declare let globalConfig:any

export default defineComponent({
  name: 'DashboardAppBar',
  data () {
    return {}
  },
  setup () {
    const store = useStore()
    // const router = useRouter()
    const { proxy } :any = getCurrentInstance()
    const config = globalConfig
    const router = useRouter()
    const userInfo = computed(() => store.getters.GET_USER_INFO)
    const responsively: ComputedRef<boolean> = computed(() => store.getters.GET_RESPONSIVELY_MODE)
    const settingDialog: Ref<boolean> = ref(false)
    const modifyDialog: Ref<boolean> = ref(false)
    const sendSms: Ref<boolean> = ref(false)

    const settingForms = reactive<ISettingForms>({
      nickname: '',
      bio: ''
    })

    const modifyForms = reactive<IModifyForms>({
      mobile: '',
      captcha: '',
      newpassword: ''
    })

    const cutover = ():void => {
      store.commit('SET_MENU_BAR', !store.getters.GET_MENU_BAR)
    }

    const switchScreen = ():void => {
      store.commit('SET_WIDE_SCREEN', !store.getters.GET_WIDE_SCREEN)
    }

    const wideScreen = computed<boolean>(() => {
      return store.getters.GET_WIDE_SCREEN
    })

    const exit = (): void => {
      proxy.$nextTick(() => {
        const routerUrl = router.resolve({ path: '/' })
        location.href = routerUrl.href
        store.commit('Logout')
      })
    }

    const editProfile = (): void => {
      proxy.$refs.settingForm.validate((valid: boolean) => {
        if (valid) {
          proxy.$axios.post('/user/profile', {
            nickname: settingForms.nickname,
            bio: settingForms.bio
          }).then((res: IResponse) => {
            if (res.code === 1) {
              ElMessage.success({
                message: res.msg,
                type: 'success'
              })
              store.commit('SET_USER_INFO', res.data)
            } else {
              ElMessage.warning({
                message: res.msg,
                type: 'warning'
              })
            }
            settingDialog.value = false
          })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 设置密码操作
    const modifyPassword = (): void => {
      proxy.$refs.modifyForm.validate((valid: boolean) => {
        if (valid) {
          proxy.$axios.post('/user/resetpwd', modifyForms)
            .then((res: IResponse) => {
              if (res.code === 200) {
                ElMessage.success({
                  message: res.msg,
                  type: 'success'
                })
              } else {
                ElMessage.warning({
                  message: res.msg,
                  type: 'warning'
                })
              }
            })
        } else {
          if (process.env.NODE_ENV === 'development') {
            console.error('字段有误')
          }
          return false
        }
      })
    }

    // 获取验证码
    const handleSms = (): void => {
      const mobile: string = modifyForms.mobile
      if (mobile !== '') {
        sendSms.value = true
        proxy.$axios.post('/sms/send', {
          mobile: parseInt(mobile),
          event: 'resetpwd'
        }).then((res: IResponse) => {
          sendSms.value = false
          if (res.code === 1) {
            ElMessage.success({
              message: res.msg,
              type: 'success'
            })
          } else {
            ElMessage.warning({
              message: res.msg,
              type: 'warning'
            })
          }
        })
      } else {
        ElMessage.warning({
          message: '请先输入手机号再获取验证码',
          type: 'warning'
        })
      }
    }

    onMounted(() => {
      settingForms.nickname = userInfo.value && userInfo.value.nickname
      modifyForms.mobile = userInfo.value && userInfo.value.mobile
    })

    return {
      settingDialog,
      modifyDialog,
      editProfile,
      userInfo,
      exit,
      settingForms,
      modifyForms,
      responsively,
      settingsRules,
      modifyRules,
      modifyPassword,
      handleSms,
      sendSms,
      cutover,
      switchScreen,
      wideScreen,
      config
    }
  }
})
</script>

<style lang="scss" scoped>
  .appbar {
    height: 100%;
    .avatar-dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .user-name {
      margin-left: 10px;
    }
    .logo {
      font-size: 24px;
      font-weight: 700;
      width: 100px;
      text-align: center;
      :deep(.el-image) {
        vertical-align: middle;
      }
    }
    .right {
      justify-content: flex-end;
      .el-switch {
        margin-right: 24px;
      }
    }
    .el-row {
      height: inherit;
    }
    .el-col {
      height: inherit;
      display: flex;
      justify-items: center;
      align-items: center;
    }
    .el-icon-s-fold {
      cursor: pointer;
    }
    .avatar-uploader {
      :deep .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
          border-color: #409EFF;
        }
      }
      &-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .el-icon-s-unfold {
      font-size: 20px;
      cursor: pointer;
    }
    .dropdown {
      cursor: pointer;
    }
    .mobile {
      .el-col {
        &:first-child {
          justify-content: flex-start;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
      .logo {
        justify-content: center;
      }
    }
  }
</style>
