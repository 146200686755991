import { ref } from 'vue'
import { validateNickname, validateBio, validateMobile, validateCaptcha, validatePassword } from './rules'

interface ISettingRules {
  nickname: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  bio: ({
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
}

// 拦截个人设置规则
export const settingsRules = ref<ISettingRules>({
  nickname: [
    {
      min: 2,
      max: 30,
      message: '昵称长度在2到30个字符',
      trigger: 'blur'
    },
    {
      validator: validateNickname,
      trigger: 'blur'
    }
  ],
  bio: [
    {
      max: 200,
      message: '个人简介长度最大支持200个字符',
      trigger: 'blur'
    },
    {
      validator: validateBio,
      trigger: 'blur'
    }
  ]
})

// 拦截注册规则接口
interface IModifyRules {
  mobile: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  captcha: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
  newpassword: ({
    min: number;
    max: number;
    message: string;
    trigger: string;
  } | {
    validator: (rule: Record<string, unknown>, value: string, callback: any) => void;
    trigger: string;
  })[];
}

// 拦截注册规则
export const modifyRules = ref<IModifyRules>({
  mobile: [
    {
      min: 11,
      max: 11,
      message: '只支持11位手机号',
      trigger: 'blur'
    },
    {
      validator: validateMobile,
      trigger: 'blur'
    }
  ],
  captcha: [
    {
      min: 4,
      max: 8,
      message: '验证码长度必须在4到8个字符',
      trigger: 'blur'
    },
    {
      validator: validateCaptcha,
      trigger: 'blur'
    }
  ],
  newpassword: [
    {
      min: 6,
      max: 30,
      message: '密码长度必须在6到30个字符',
      trigger: 'blur'
    },
    {
      validator: validatePassword,
      trigger: 'blur'
    }
  ]
})
